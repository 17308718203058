<template>
  <div class="contact">
    <TitlePage></TitlePage>
    <CommonTitle :text="text" :cont="cont"></CommonTitle>
    <div class="contact-cont">
      <div class="contL" v-html="content.content"></div>
      <div v-if="fullWidth > 900" class="contR"><User></User></div>
    </div>
  </div>
</template>

<script>
import TitlePage from "../../components/TitlePage";
import CommonTitle from "../../components/CommonTitle";
import User from "../../components/User";
import request from "../../api/request";
export default {
  name: "contact",
  components: {
    TitlePage,
    CommonTitle,
    User,
  },
  data() {
    return {
      text: this.$t("联系我们"),
      cont: this.$t("企业合作以及服务提供商"),
      fullWidth: 0,
      content: "",
    };
  },
  beforeCreate() {
    var query = this.$route.query;
    let token = query.token;
    if (token) {
      this.$store.commit("setToken", token);
      this.$router.replace({
        path: this.$route.path,
      });
    }
  },
  mounted() {
    this.handleResize();
    this.getContack();
  },
  methods: {
    getContack() {
      let _this = this;
      request.get("/terms/10", {}).then(function (res) {
        if (res.status == 200) {
          _this.content = res.data;
        }
      });
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
.contact {
  .contact-cont {
    max-width: 1220px;
    margin: 20px auto;
    min-height: 1000px;
    display: flex;
    @media only screen and (max-width: 900px) {
      margin: 20px;
    }
    .contL {
      width: 70%;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}
</style>
