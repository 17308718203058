<template>
  <div class="common-title">
    <div class="main">
      <p>{{ text }}</p>
      <p>{{ cont }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: {
      type: String,
      required: true,
    },
    cont: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.common-title {
  background-color: #fff;
  padding: 20px 0;
}
.common-title .main {
  max-width: 1200px;
  margin: 0 auto;
}
.common-title p:nth-child(1) {
  font-size: 24px;
  color: #333333;
}
.common-title p:nth-child(2) {
  font-size: 18px;
  color: #b3b3b3;
}
@media only screen and (max-width: 900px) {
  .common-title {
    padding-left: 20px;
  }
}
</style>
