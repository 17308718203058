<template>
    <div class="title-page">
        <div class="cont">
            <div class="font-w">panggame{{this.$t('客服中心')}}</div>
            <ul>
                <li
                @click="tab(index)"
                v-for="(item,index) in navList"
                :key="index"
                >
                <router-link
                 active-class="active"
                 :to="item.path">{{item.name}}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            navIndex:0,
            navList:[
                {
                    name:'MY page',
                    path: "/mypage/center",
                },
                {
                    name:this.$t('联系我们'),
                    path: "/contact",
                },
                {
                    name:this.$t('政策中心'),
                    path: "/policy",
                },
            ]
        }
    },
    methods:{
        tab(index) {
            this.navIndex = index;
        },
    }
}
</script>

<style lang="scss" scoped>
.title-page{
    background-color: #252A2F;
    color: #fff;
    height: 80px;
    line-height: 80px;
    .cont{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        ul{
            display: flex;
            li{
                font-size: 20px;
                cursor: pointer;
                white-space: nowrap;
                a{
                    color: #BDBDBD;
                    display: block;
                    padding: 0 30px;
                    font-size: 22px;
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }
        .font-w{
            font-size: 32px;
            margin-right: 30px;
        }
    }
}
.active{
    border-bottom: 1px solid #2C82FF;
    background-color: #222426;
    color: #fff;
}
</style>